@tailwind base;
@tailwind components;
@tailwind utilities;

.home-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 670px;
    background-color: #D9D9D9;
}
